import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@iwt-expose/environments/environment';
import { OcmSettingsAggregateResponse } from '@iwt-expose/models/ocm-settings-response';
import { LoggerService } from '@iwt-expose/services/logger-service/logger.service';
import { GetExposeLinkResponse } from '@iwt-expose/models';
import { HttpRequestOptions } from '@estate/models';
import { GetExposeResponse } from '@iwt-estate/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class ExposeService {
  private baseUrl: string;

  constructor(
    private httpClient: HttpClient,
    private loggerService: LoggerService,
  ) {
    this.baseUrl = environment.baseUrl;
  }

  async getOcmSettings(
    tenantUuid: string,
  ): Promise<OcmSettingsAggregateResponse> {
    const url = `${this.baseUrl}/v1/ocm/settings/${tenantUuid}`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe({
        next: (value) => resolve(value as OcmSettingsAggregateResponse),
        error: (error) => {
          this.loggerService?.error(error);
          reject(error);
        },
      });
    });
  }

  async getExpose(exposeUrlSlug: string): Promise<GetExposeResponse> {
    const url = `${this.baseUrl}/v1/exposes/${exposeUrlSlug}`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe({
        next: (value) => resolve(value as GetExposeResponse),
        error: (error) => {
          this.loggerService?.error(error);
          reject(error);
        },
      });
    });
  }

  async getExposeLink(
    exposeUrlSlug: string,
  ): Promise<GetExposeLinkResponse | undefined> {
    const url = `${this.baseUrl}/v1/exposes/links/${exposeUrlSlug}`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe({
        next: (value) => resolve(value as GetExposeLinkResponse),
        error: (error) => {
          return resolve(undefined);
        },
      });
    });
  }

  async getImage(imageUrl: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(imageUrl, {
          headers: {
            'Cache-Control': 'no-cache',
            'Access-Control-Allow-Origin': '*',
          },
          responseType: 'blob',
        })
        .subscribe({
          next: (value) => resolve(value as Blob),
          error: (error) => {
            this.loggerService?.error(error);
            reject(error);
          },
        });
    });
  }

  async approveExposeLink(exposeUrlSlug: string): Promise<void> {
    const url = `${this.baseUrl}/v1/exposes/links/${exposeUrlSlug}/approve`;

    const options = {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    } as HttpRequestOptions;

    return new Promise((resolve, reject) => {
      this.httpClient.post(url, null, options).subscribe({
        next: () => resolve(),
        error: (error) => {
          this.loggerService?.error(error);
          reject(error);
        },
      });
    });
  }

  async visitExposeLink(exposeUrlSlug: string): Promise<void> {
    const url = `${this.baseUrl}/v1/exposes/links/${exposeUrlSlug}/visit`;

    const options = {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    } as HttpRequestOptions;

    return new Promise((resolve, reject) => {
      this.httpClient.post(url, null, options).subscribe({
        next: () => resolve(),
        error: (error) => {
          this.loggerService?.error(error);
          reject(error);
        },
      });
    });
  }
}
