import { Injectable } from '@angular/core';

import { LoggerServiceInterface } from './logger.service.interface';

@Injectable({
  providedIn: 'root',
})
export class LoggerService implements LoggerServiceInterface {
  private console: Console = window.console;

  public debug(...data: unknown[]): void {
    this.console.debug(data);
  }

  public info(...data: unknown[]): void {
    this.console.info(data);
  }

  public warn(...data: unknown[]): void {
    this.console.warn(data);
  }

  public error(...data: unknown[]): void {
    this.console.error(data);
  }
}
