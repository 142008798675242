export const environment = {
  production: true,
  languageUrl: 'https://estate.dev.estatepro.de',
  baseUrl: 'https://api.estate.dev.empro.ai',
  actionApiUrl: 'https://main-estatecloud-action.estate-dev.immocloud.io/v1',
  permissionApiUrl: 'https://main-permissions.estate-dev.immocloud.io',
  userApiUrl: 'https://nest.api.user.dev.empro.ai/v1',
  websocketUrl: 'wss://main-estate-websocket.dev.empro.ai',
  is24RestApiUrl: 'https://rest.sandbox-immobilienscout24.de/restapi/',
  ocmUrl: 'https://main-estatecloud-ocm.estate-dev.immocloud.io',
  estate_online_expose_pdf: true,
};
